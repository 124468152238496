import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const getImageSpan = ( width, height) => {
  const ratio = width/height;
  // console.log("Ratio = " + ratio + ", for width/height = " + width + "/" + height);
  const span = Math.floor( (ratio < 0.8) ? (Math.random() * 2) + 2 : ((ratio === 1) ? 2 : (Math.random() * 3) + 1) );
  // console.log("Span = " + span);
  return span;
}

const EyewearBrandTemplate = ({ data: { eyewearBrand } }) => {

  return (
    <Layout>
      <Seo title={'Eyewear - ' + eyewearBrand.brandFields.eyewearCollection.name + " - " + eyewearBrand.title} description={eyewearBrand.brandFields.brandDescription} />
      <section className="page-header">
        <h1>{eyewearBrand.title}</h1>
      </section>
      <section className="page-intro">
        <div className="page-intro-content">
          {eyewearBrand.brandFields.brandDescription}
        </div>
        <div className="col-start-8 lg:col-start-6 col-end-13 lg:col-end-12 row-start-1 row-end-1 z-10 h-2/4 md:h-full flex justify-end items-end">
          <GatsbyImage 
            image={getImage(eyewearBrand.brandFields.brandPrimaryImage.localFile)}
            alt={eyewearBrand.title} 
            className="inline-block mx-2 w-12/12 h-full min-h-full"
          />
        </div>
        <div className="section-bg-right col-start-9 lg:col-start-7 col-end-13 -top-16 -right-6 lg:right-4 h-full"></div>
      </section>

      {eyewearBrand.brandFields.brandGallery &&
      <section className="container grid grid-cols-[repeat(auto-fill,minmax(16rem,1fr))] gap-4 auto-rows-10 mt-20 mb-32">
        {eyewearBrand.brandFields.brandGallery.map(brandGalleryImg => (
          <div key={brandGalleryImg.id} className={"row-span-" + getImageSpan(brandGalleryImg.localFile.childImageSharp.gatsbyImageData.width, brandGalleryImg.localFile.childImageSharp.gatsbyImageData.height) + " grid group z-10"}>
            <div className="col-span-full row-span-full z-10 overflow-hidden transition duration-300 ease-in-out group-hover:bg-black group-hover:bg-opacity-20">
              <GatsbyImage 
                image={getImage(brandGalleryImg.localFile)}
                alt={eyewearBrand.title} 
                className="w-full h-full transform transition duration-300 ease-in-out group-hover:scale-110 group-hover:duration-300 mix-blend-overlay"
              />
            </div>
          </div>
        ))}
      </section>
      }

      <div className="text-center -mt-16 mb-16 ">
          <Link to={eyewearBrand.brandFields.eyewearCollection.uri} className="button bg-dl-orange hover:bg-black text-white mr-2" title={"Terug naar collectie " + eyewearBrand.brandFields.eyewearCollection.name}>Terug naar {eyewearBrand.brandFields.eyewearCollection.name}</Link>
      </div>


      {/* If this hidden block is removed, all images in gallery are displayed in same size. Weird */}
      <section className="container grid grid-cols-[repeat(auto-fill,minmax(16rem,1fr))] gap-4 auto-rows-10 mt-20 mb-32 hidden">
        <div className="row-span-1 grid group z-10">
        </div>
        <div className="row-span-2 grid group z-10">
        </div>
        <div className="row-span-3 grid group z-10">
        </div>
      </section>
  </Layout>
  )
}

export default EyewearBrandTemplate

export const pageQuery = graphql`
  query EyewearBrandById(
    $id: String!
  ) {
    eyewearBrand: wpBrand(id: {eq: $id}) {
      id
      title
      uri
      brandFields {
        brandDescription
        eyewearCollection {
          name
          uri
        }
        brandPrimaryImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        brandGallery {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
